<!-- 区企互动 -->
<template>
  <div>
    <div>
      <div
        style="
          border-bottom: 1px solid #e6e6e6;
          padding: 10px 15px;
          position: relative;
        "
      >
        <div style="display: inline-block; width: 60px">
          <img style="width: 100%" src="../../assets/zs.png" alt="" />
        </div>
        <div
          style="
            display: inline-block;
            position: absolute;
            top: 17px;
            margin-left: 15px;
          "
        >
          <div style="font-size: 18px">吉利华阳产业集聚区</div>
          <div>区企互动</div>
        </div>
      </div>
      <div class="inputFrom">
        <div>企业名称</div>
        <div style="margin-top:10px">
          <input type="text" placeholder="请输入企业名称" style="border:none;border-bottom:1px solid #999999" />
        </div>
        <div style="margin:25px 0px">
          <span>华阳平台评分</span>
          <van-rate
            v-model="datafrom.quality"
            :size="20"
            :gutter="10"
            color="#FF9E14"
            style="margin-left:30px"
          />
        </div>
        <div style="margin-top:10px">意见建议</div>
        <div>
          <textarea
            v-model="datafrom.remark"
            rows="6"
            placeholder="欢迎阐述自己的观点"
            style="
              width: 90%;
              margin:15px 0px;
              border:none;
              font-size: 14px;
              border-radius: 5px;
              background:#F7F7F7;
              padding:8px 15px;
            "
          ></textarea>
        </div>
        <!-- <span>合理化建言，一经采纳，予以奖励！</span> -->
      </div>
      <div class="fromButton">
        <van-button
          style="width: 80%; 
          margin: 10px 10%;
          border-radius:25px"
          type="info"
          @click="tijiao()"
          >提 交</van-button
        >
      </div>
    </div>
    <div>
      <van-tabbar v-model="active">
        <van-tabbar-item icon="home-o" to="/huayang/classroom"
          >知识大讲堂</van-tabbar-item
        >
        <van-tabbar-item icon="qr" to="/huayang/serve"
          >企业信息</van-tabbar-item
        >
        <van-tabbar-item icon="apps-o" to="/huayang">中介超市</van-tabbar-item>
        <van-tabbar-item icon="cluster-o" to="/huayang/interaction"
          >区企互动</van-tabbar-item
        >
        <van-tabbar-item icon="phone-circle-o" to="/huayang/lianluo"
          >联系我们</van-tabbar-item
        >
      </van-tabbar>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Rate } from "vant";
import { Dialog } from "vant";
Vue.use(Dialog);
Vue.use(Rate);
export default {
  data() {
    return {
      active: 3,
      datafrom: {
        quality: "0", //评分质量1
        serve: "0", //评分质量2
        remark: "", //建言献策
      },
    };
  },
  methods: {
    tijiao() {
      if (this.datafrom.remark == "") {
        Dialog.alert({
          title: "提示",
          message: "请输入建议内容!",
        }).then(() => {
          // on close
        });
        return false;
      }
      Dialog.alert({
        title: "提示",
        message: "提交成功!",
      }).then(() => {
        this.datafrom.remark = "";
      });
    },
  },
};
</script>
<style>
.inputFrom {
  margin: 15px 8%;
}
.inputFrom input {
  width: 93%;
  margin: 8px 2%;
  padding: 8px 5px;
  font-size: 14px;
  border: 1px solid #e6e6e6;
}
.margin {
  margin: 15px 30px;
}
</style>